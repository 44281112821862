import * as React from "react";
import {Link} from "gatsby";
import {buildTagRoute} from "../util/routes";
import {highlightSearchQuery} from "../util/stringUtil";

interface BlogTagProps {
    children?: any;
    label?: string;
    selected?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    searchQuery?: string;
}

const BlogTag = (props: BlogTagProps) => {
    // TODO DARK BORDER
    return (
        <Link
            to={buildTagRoute(props.label || "")}
            className="border blog-tag rounded px-2 py-0.5 bg-gray-50 hover:text-blue-500 hover:bg-white hover:border-blue-500 dark:text-gray-300 dark:bg-gray-900 dark:hover:text-blue-500"
            style={{fontSize: "13.5px"}}
            onClick={() => props.onClick}
        >
            {
                props?.searchQuery ? highlightSearchQuery(props.label, props.searchQuery) : props.label
            }
        </Link>
    )
}

export {BlogTag};
