import * as React from "react";

export const articleDateToString = (date: Date) => {
    return date.toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
}

export const slugify = (str: string): string => {
    return str.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
}

export const highlightSearchQuery = (text: string, searchQuery: string) => {
    if (!searchQuery) {
        return text;
    }

    if (!searchQuery.trim()) {
        return <span>{text}</span>
    }
    const regex = new RegExp(`(${searchQuery})`, 'gi')
    const parts = text.split(regex)
    return (
        <span>
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <span className="bg-green-500 text-white" key={i}>{part}</span> :
                    <span key={i}>{part}</span>
            ))}
    </span>
    );
}

export const addDecimalToThousands = (str) => {
    if (!str) {
        return str;
    }
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//  new Date(date + ' 00:00:00').toLocaleString('default', {
//         month: 'long',
//         day: 'numeric',
//         year: 'numeric',
//     });
