import {useQueryClient} from "react-query";
import axios from "axios";
import {useEffect, useState} from "react";

const useGetArticleViews = (slug: string | string[]) => {
    const queryClient = useQueryClient();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        queryClient.fetchQuery(
            `article-views-${Array.isArray(slug) ? slug.sort() : slug}`,
            async () => {
                let queryString = '';

                if (Array.isArray(slug)) {
                    for (let s of slug) {
                        queryString += `slug=${s}&`;
                    }
                } else {
                    queryString = `slug=${slug}`;
                }

                return await axios.get(process.env.GATSBY_GET_ARTICLE_VIEWS_ENDPOINT + `?${queryString}`);
            }
        ).then((res) => {
            setLoading(false);
            setData(res.data);
        });
    }, []);

    return { isLoading: loading, error: null, views: data?.views || 0 };
}

export {useGetArticleViews};
